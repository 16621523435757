import axios from 'axios';
import { Toast } from 'vant';
import router from '@/router/index'
import Cookies from 'js-cookie'
const http = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 30000000
})

// 响应拦截器
http.interceptors.response.use(
    response => {
      // 回复前该做点什么
      if(response.data.code === 401){
        sessionStorage.clear()
        Toast.fail('登录失效，请重新登录')
        router.push('/')
      }
        return response;
    },
    error => {
      // 发生错误该做什么
      return Promise.reject(error);
    }
  );
  // 请求拦截器
  http.interceptors.request.use(
    config => {
      // if(sessionStorage.getItem('token')){
      //   config.headers.authorization = sessionStorage.getItem('token')
      // }
      const token = Cookies.get('token')
      if(token){
        config.headers.authorization = token
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );
  
  export { http };